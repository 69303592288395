import { useCartStore } from "~/pinia/cart"
import { useAuthStore } from "~/pinia/auth"
import { useNotificationStore } from "~/pinia/notifications"

export default function useErrorHandling() {
  const nuxtApp = useNuxtApp()
  const alerts = ref<Alert[]>([])
  const authStore = useAuthStore()
  const notificationStore = useNotificationStore()

  const clearAlerts = () => {
    alerts.value = []
  }

  const router = nuxtApp.$router

  const errorMessage = (error: { detail: string; title: string }) => {
    return { message: error.detail || error.title || error, type: "error" }
  }

  const redirectMessages = (errors: string[]) => {
    errors.forEach((error: any) => {
      notificationStore.add({
        message: error,
        type: "error",
      })
    })
  }

  const handleBadRequest = async () => {
    const previousLoggedInState = authStore.loggedIn
    authStore.resetState()
    await authStore.getSession()
    if (previousLoggedInState && !authStore.loggedIn) {
      // We've been logged out. Our order is not anymore.
      useCartStore().resetCart()
      await router.push("/").then(() => {
        redirectMessages(["You've been logged out. Please log in again."])
      })
    } else {
      alerts.value.push({
        message: "Something went wrong. Please try again.",
        type: "warning",
      })
      setTimeout(clearAlerts, 3000)
    }
  }

  const handleError = async (error: any) => {
    if (error?.status === 400 || error?.status === 418) {
      // A 400/418 error code indicates a "bad request", most likely a CSRF
      // failure caused by an invalid `cs_session_id` cookie.
      await handleBadRequest()
    } else if (import.meta.client && error?.data?.errors) {
      error.data.errors.forEach((error: any) => {
        alerts.value.push(errorMessage(error))
      })
    } else if (error?.statusText) {
      alerts.value.push(errorMessage(error?.statusText))
    } else {
      console.error(error)
      createError(error.message || error)
    }
  }

  return {
    alerts,
    handleError,
    errorMessage,
    clearAlerts,
  }
}
