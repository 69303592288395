import { deserialize } from "@alchemy_cms/json_api"
import { cartIncludes } from "~/lib/orderIncludes"

import useApi from "~/composables/useApi"

interface AddToCartData {
  text?: string
  items?: any[]
  totalItemCount?: number
  totalDisplayPrice?: string
  errors?: any[]
  displaySubtotal?: string
  warnings?: any[]
}

export const useCartStore = defineStore("cart", () => {
  const { api } = useApi()
  const cartInfo = ref("")
  const itemCount = ref(0)
  const order: Ref<SpreeCartOrder | null> = ref(null)
  const cartWarnings: Ref<any[]> = ref([])
  const addToCartDialog: Ref<AddToCartData> = ref({
    text: "",
    items: [],
    totalItemCount: 0,
    totalDisplayPrice: "",
    errors: [],
  })

  const promotions = ref([
    {
      label: "hi",
      displayAmount: "55",
    },
  ])

  const addToCartDialogOpen = ref(false)

  const setCartInfo = (info: string) => {
    cartInfo.value = info
  }

  const setFromOrderJson = (orderJson: SpreeCartOrder) => {
    cartWarnings.value = orderJson.cartWarnings
    itemCount.value = orderJson.itemCount
    order.value = orderJson
  }

  const setAddedToCartDialog = (data: AddToCartData) => {
    addToCartDialog.value = data
    addToCartDialogOpen.value = true
  }

  const closeAddedToCartDialog = () => {
    addToCartDialogOpen.value = false
  }

  const resetCart = () => {
    cartWarnings.value = []
    itemCount.value = 0
    order.value = null
  }

  const setLineItemQuantity = (lineItemId: string, quantity: number) => {
    const item = order.value?.lineItems?.find(
      (li: OrderLineItem) => li.id === lineItemId,
    )
    if (item) {
      item.quantity = quantity
    }
  }

  const updateCart = async () => {
    setCartInfo("")

    const lineItemParams = order.value?.lineItems?.map((li) => ({
      id: li.id,
      quantity: li.quantity,
      variant_id: li.variant.id,
    }))

    const data = await api("/jsonapi/order", {
      method: "PATCH",
      body: {
        foo: "bar",
        line_items: lineItemParams,
      },
      params: {
        include: cartIncludes,
      },
    })

    const orderJson = deserialize(data)
    setFromOrderJson(orderJson)
  }

  const emptyCart = () => {
    return api("/jsonapi/order", {
      method: "DELETE",
    }).then(() => {
      resetCart()
    })
  }

  const loadCart = () => {
    return api("/jsonapi/order", {
      params: {
        include: cartIncludes,
      },
    }).then((serializedOrder: any) => {
      setFromOrderJson(deserialize(serializedOrder))
    })
  }

  const updateLineItem = async (lineItem: OrderLineItem, quantity: string) => {
    const originalQuantity = lineItem.quantity

    try {
      setLineItemQuantity(lineItem.id, parseInt(quantity))
      await updateCart()
    } catch (error) {
      setLineItemQuantity(lineItem.id, originalQuantity)
      throw error
    }
  }

  const addToCart = async (cartItems: CartItem[], text: string = "") => {
    try {
      const data = await api("/jsonapi/order", {
        method: "POST",
        body: { line_items: cartItems },
        params: {
          include: cartIncludes,
        },
      })

      const orderJson = deserialize(data)
      const itemsAdded =
        orderJson.lineItems?.filter(
          (li: OrderLineItem) => li.quantityAdded > 0,
        ) || []

      setFromOrderJson(orderJson)
      setAddedToCartDialog({
        text,
        items: itemsAdded,
        totalItemCount: orderJson?.itemCount,
        displaySubtotal: orderJson?.displaySubtotal,
        warnings: orderJson?.cartWarnings,
      })
    } catch (error: any) {
      if (error.response?.status === 400 || error.response?.status === 418) {
        throw error
      } else {
        setAddedToCartDialog({
          errors: error?.response?.data?.errors || [],
        })
      }
    }
  }

  const createShippingQuote = (quoteParams: {
    zipcode: string
    countryId: string
  }) => {
    return api("/jsonapi/cart_shipping_quotes", {
      method: "POST",
      body: {
        zipcode: quoteParams.zipcode,
        country_id: quoteParams.countryId,
      },
      params: {
        include: cartIncludes,
      },
    }).then((data) => {
      setFromOrderJson(deserialize(data))
    })
  }

  const applyRewardPromotion = async (id: string) => {
    const data = await api("/jsonapi/rewards", {
      method: "POST",
      body: { id },
      params: {
        include: cartIncludes,
      },
    })

    const orderJson = deserialize(data)
    setFromOrderJson(orderJson)
  }

  const removeRewardPromotion = async (id: string) => {
    const data = await api("/jsonapi/rewards/" + id, {
      method: "DELETE",
      params: {
        include: cartIncludes,
      },
    })

    const orderJson = deserialize(data)
    setFromOrderJson(orderJson)
  }

  return {
    addToCart,
    emptyCart,
    cartInfo,
    updateCart,
    updateLineItem,
    loadCart,
    itemCount,
    order,
    cartWarnings,
    addToCartDialog,
    addToCartDialogOpen,
    setCartInfo,
    setFromOrderJson,
    setAddedToCartDialog,
    closeAddedToCartDialog,
    resetCart,
    setLineItemQuantity,
    createShippingQuote,
    promotions,
    applyRewardPromotion,
    removeRewardPromotion,
  }
})
